<template>
    <layout>
        <div class="title center bottom_link fs_24">由于法律规定要求 请先绑定手机 <router-link to="/login" class="el-icon-arrow-left"></router-link></div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
            <el-form-item label="" prop="mobile">
                <el-input placeholder="请输入手机号码" class="flex_c" maxlength="11" type="number" v-model.trim="ruleForm.mobile" @keyup.enter="submit">
                    <template #prefix>
                        <img src="@/assets/imgs/icon/mobile-icon.png" class="icon" alt="">
                    </template>
                </el-input>
            </el-form-item>
            <el-form-item label="" prop="code">
                <el-input placeholder="请输入验证码" maxlength="6" type="number" v-model.trim="ruleForm.code" @keyup.enter="submit">
                    <template #prefix>
                        <img src="@/assets/imgs/icon/pas-icon.png" class="icon" alt="">
                    </template>
                    <template #suffix>
                        <p class="btn">发送验证码</p>
                    </template>
                </el-input>
            </el-form-item>
            <el-button type="primary" class="fs_16 loginer" @click="submit">登 录</el-button>
            <div class="mt_20 flex_c">
                <el-checkbox v-model="checked" size="medium"><span class="fs_14 color_999">同意</span></el-checkbox>
                <a href="/agreement" target="_balck" class="fs_14 xieyi">《职路数字平台用户服务协议》</a>
            </div>
        </el-form>
    </layout>
</template>

<script>
import layout from './layout/main'
export default {
	name: 'Bind',
	components: { layout },
	data() {
		var checkMobile = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入手机号码'))
			} else {
				callback()
			}
		}
		return {
			ruleForm: {
				mobile: '',
				code: ''
			},
			checked: true,
			rules: {
				mobile: [
					{ validator: checkMobile, trigger: 'change' }
				],
				code: [
					{ required: true, message: '请输入验证码', trigger: 'change' }
				]
			}
		}
	},
	methods: {
		submit() {
			this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					alert('submit!')
				} else {
					console.log('error submit!!')
					return false
				}
			})
		}
	}
}
</script>

<style lang='less' scoped>
@import url('../../assets/style/login.less');
.title {
    padding: 20px 0;
    position: relative;
    .el-icon-arrow-left {
        position: absolute;
        top: 50%;
        left: -50px;
        transform: translateY(-40%);
        color: #CCCCCC;
    }
}

</style>