<template>
    <div class='login flex_cen'>
        <img src="@/assets/imgs/login-bg.png" class="bg" alt="">
        <div class="form flex">
            <div class="left">
                <img src="@/assets/imgs/layout/logo.png" class="logo" alt="职路数字">
                <div class="flex_c">
                    <img src="@/assets/imgs/layout/sacn.png" class="icon" alt="扫码离职">
                    <div class="flex_1">
                        <p class="fs_14 info_color">扫码离职</p>
                        <p class="fs_12 color_999 mt_10">完善人事流程<br/>以法规要求来降低风险</p>
                    </div>
                </div>
                <div class="flex_c">
                    <img src="@/assets/imgs/layout/share.png" class="icon" alt="共享交换">
                    <div class="flex_1">
                        <p class="fs_14 info_color">共享交换</p>
                        <p class="fs_12 color_999 mt_10">即时共建共享<br/>促进HR精准高效的招聘</p>
                    </div>
                </div>
                <div class="flex_c">
                    <img src="@/assets/imgs/layout/free.png" class="icon" alt="永久免费">
                    <div class="flex_1">
                        <p class="fs_14 info_color">永久免费</p>
                        <p class="fs_12 color_999 mt_10">永久免费交换<br/>企业年省百万人事成本</p>
                    </div>
                </div>
            </div>
            <div class="right flex_1" :class="{auto: auto}">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
	data() {
		return {}
	},
	props: {
		isScroll: {
			default: false,
			type: Boolean
		}
	},
	computed: {
		auto() {
			return this.isScroll
		}
	}
}
</script>

<style lang='less' scoped>
@maincolor: #5AB0FF;
.login {
    height: 100vh;
    background: @maincolor;
    position: relative;
    overflow: hidden;
    .bg {
        position: absolute;
        width: 100%;
        height: 77vh;
        left: 0;
        bottom: -17vh;
        z-index: 1;
    }
    .form {
        width: 880px;
        height: 546px;
        border-radius: 20px;
        background: #f8fbff;
        overflow: hidden;
        position: relative;
        z-index: 2;
        .left {
            padding: 70px 50px;
            background: #f8fbff;
            width: 360px;
            height: 100%;
            .logo {
                width: 150px;
                height: auto;
                margin-bottom: 60px;
            }
            .icon {
                width: 67px;
                height: 67px;
                border-radius: 100%;
                margin-right: 10px;
            }
            > div {
                margin-bottom: 30px;
            }
        }
        .right {
            padding: 20px 80px;
            height: 100%;
            background: #fff;
            max-height: 556px;
            &.auto {
                overflow-y: scroll;
            }
        }
    }
}
</style>